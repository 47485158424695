/* Navbar.module.css */

/* Resetting some default styles */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
  }
  
  /* Navbar Styles */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2c2c2c; /* Charcoal Black */
    padding: 15px 0;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .logo a {
    font-size: 24px;
    color: #ffffff; /* Pure White */
    text-decoration: none;
    font-weight: bold;
  }
  
  .navLinks {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .navLinks li {
    margin-right: 20px;
  }
  
  .navLinks a {
    color: #ffffff; /* Pure White */
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease;
  }
  
  .navLinks a:hover {
    color: #1e90ff; /* Electric Blue on hover */
  }
  
  /* Responsive */
  @media screen and (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .navLinks {
      width: 100%;
      text-align: left;
      padding-top: 10px;
    }
  
    .navLinks li {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  